<template>
  <div :style="[accentStyle]">
    <!--countdown-->
    <div class="midDiv2">
      <div
        style="background:black;border-radius: 15px;padding:15px;border: solid 3px var(--pr-color);"
        :style="{'border-color' : accentColour }"
      >
        <div>
          <div
            class="h-100 w-100 align-items-center justify-content-center"
            style="text-align:center;margin-top:20px;color: var(--override-color) !important;"
          >
            <div style="margin-bottom:-30px;">
              MEGA PRIZE POT
            </div>
            <div style="font-size:6rem;">
              £5000
            </div>
            <div style="margin-top:-20px;">
              UP FOR GRABS EVERY DRAW!
            </div>
            <div style="border-top:solid 1px var(--override-color);width: 80%;margin-left: 10%;margin-top: 15px;" />
            <div style="color:white;margin-top: 30px;font-size:1.3rem;">
              NEXT DRAW IN
            </div>
          </div>
          <div class="h-100 w-100 d-flex align-items-center justify-content-center">
            <FlipCountdown
              style="width: 100%;color: var(--override-color) !important;"
              :large="false"
              :accent-colour="accentColour"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import FlipCountdown from '../countdown/flipCountdown.vue'

export default {
  name: 'Countdown',
  components: { FlipCountdown },
  props: {
    accentColour: {
			type: String,
			default: "#1FFC35"
		}
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    accentStyle() {
			return {
				"--override-color": this.accentColour,
			}
		},
  }
}
</script>
