<template>
  <leaderboard />
</template>

<script>
import leaderboard from '@/components/leaderboard/leaderboard.vue'

export default {
  name: 'Leaderboard',
  components: {
    leaderboard
  },
  data () {
    return {
    }
  },
  computed: { },
  watch: { },
  created () { },
  methods: { }
}
</script>