<template>
  <div class="navTopDiv" style="background:black;height:100px;margin-bottom:10px;">
    <Stars
      style="position: absolute;top: 0;left: 0;height: 100px;width: 100%;z-index: 0;"
      class="starsMobileView"
      :star-count="100"
      image-path="stars2.png"
    />
    <div class="topDiv1">
      <img
        src="https://play.grassrootslottery.co.uk/img/logo.png"
        style="height:100px;"
        class="mr-2 mobGrlLogo"
      >
    </div>
    <div class="topDiv2">
      <img
        v-if="clubLogoUrl"
        :src="clubLogoUrl"
        :class="accentColour === '#1FFC35' ? 'badgePadding' : ''"
        style="height:108px;margin: auto 0;"
        class="mr-2 mobClubLogo desktopOnly"
      >
    </div>
    <div class="topDiv3">
      <mdb-btn
        v-if="!userData.aut"
        style="width:110px !important;font-size:0.8rem !important;padding:10px !important;float: right;margin-top: 30px !important;margin-right:20px !important;color: var(--override-color) !important;border: solid 1px var(--override-color) !important;"
        class="btn primary-btn btn-right btn-outline btn-radius btn-sm btn-md desktopOnly"
        @click="$parent.login = true"
      >
        LOGIN
      </mdb-btn>
      <mdb-btn
        v-if="!userData.aut"
        style="width: 65px !important;padding: 4px !important;float: right;margin-top: 10px !important;margin-right: 15px !important;height: 25px !important;right: 0;position: absolute;color: var(--override-color) !important;border: solid 1px var(--override-color) !important;"
        class="btn primary-btn btn-right btn-outline btn-radius btn-sm btn-md mobileOnly"
        @click="$parent.login = true"
      >
        LOGIN
      </mdb-btn>
      <mdb-btn
        style="width:110px !important;font-size:0.8rem;padding:10px !important;float: right;margin-top: 30px !important;margin-right:20px !important;background-color: var(--override-color) !important;"
        class="btn primary-btn btn-radius btn-sm p-0 m-0 topDiv3PlayBtn"
        @click="playNow()"
      >
        PLAY NOW
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Stars from '@/components/UI/stars.vue'

export default {
  name: 'LeaderBoardHeader',
  components: {
    Stars
  },
  props: {
    accentColour: {
			type: String,
			default: "#1FFC35"
		},
    clubLogoUrl: {
			type: String,
			default: ""
		}
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    accentStyle() {
			return {
				"--override-color": this.accentColour,
			}
		},
  },
  methods: {
    playNow() {
      this.$router.push('/').catch(() => {})
    }
  }
}
</script>
