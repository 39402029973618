<template>
  <div style="border-radius: 5px;width:100%;height: 100%; overflow: auto;padding:0px !important;margin-top:0px;font-size: 0.9rem;">
    <div class="p-3" style="margin-top:10px;">
      <Login
        v-if="page === 'login'"
        @setPage="page = $event"
      />
      <ForgottenPassword
        v-if="page === 'forgottenPassword'"
        @setPage="page = $event"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Login from './login.vue'
import ForgottenPassword from './forgottenPassword.vue'

export default {
  name: 'AuthPanel',
  components: { Login, ForgottenPassword },
  data () {
    return {
      page: 'login',
      visibility: 'password',
      buttonClick: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ])
  },
  created () {
  }
}
</script>

<style scoped>
.isActive {
  background: var(--pr-color) !important;
  border: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: black !important;
}
.nav-link {
  background: var(--bg-color);
  border: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}
.nav-border-bottom {
  border-bottom: 5px solid var(--pr-color) !important;
}
</style>
